///
/// @file base/_typography.scss
///
/// \brief Reusable, generic typographic styles used throughout the site.
///
/// Guidelines:
///
/// No layout-related properties (float, margin, padding, position, display,
/// etc.), no template-specific stuff, try to follow the existing naming
/// conventions and method of creating both mixins and extendable placeholders.

// Base styles: each typeface has optimal type treatment that remains consitent
// across the site.
// @setup new brand - examples of how to change to values using web fonts
// $font--heading: "Copperplate Gothic Std 32 AB", sans-serif;
// $font--subheading: "ITC Franklin Gothic", sans-serif;
// $font--text: "Adobe Caslon Pro", serif;
// $font--cta: "Typewriter Elite MT Std", serif;
// $font--typewriter: "Typewriter Elite MT Std", serif;

$font--serif: serif !default;
$font--sans: sans-serif !default;
$font--mono: monospace !default;
$font--text: 'Helvetica Neue LT Pro', sans-serif !default;
$font--heading: 'Edwardian Script ITC', serif !default;
$font--strong: 'Impact', sans-serif !default;
$font--english--regular: 'English Regular', sans-serif !default;

///
/// Mixins & Extendables
///
/// These are the generic typography styles across the site.
/// Each style is both a mixin, a silent class and a class.
/// Apply the class directly to the template mark up or use @extend
/// whenever possible, use the mixin version when working inside a
/// media query.
///
///  WARNING only extend silent classes, do NOT @extend regular classes
///
/// Example NO:
/// .store-locator__header {
///   @extend .headline--secondary;
/// }
///
/// Example YES:
/// .store-locator__header {
///   @extend %headline--secondary;
/// }
///
/// Example with Media Query:
/// .store-locator__header {
///   @extend %headline--secondary;
///   @media #{$medium-up} {
///     @include headline--primary;
///   }
/// }
///

@mixin font--text {
  font-family: $font--text;
  font-weight: normal;
  font-size: $base-font-size-mobile;
  line-height: $base-line-height-mobile;
  letter-spacing: 0.02em;
  @include breakpoint($medium-up) {
    font-size: $base-font-size-desktop;
    line-height: $base-line-height-desktop;
  }
}

@mixin font-face--helvetica-light-oblique {
  font-family: $font--text;
  font-weight: 300;
  font-style: oblique;
}

@mixin font-face--helvetica-light {
  font-family: $font--text;
  font-weight: 300;
  font-style: normal;
}

@mixin font-face--helvetica-roman {
  font-family: $font--text;
  font-weight: normal;
  font-style: normal;
}

@mixin font-face--helvetica-medium {
  font-family: $font--text;
  font-weight: 500;
  font-style: normal;
}

@mixin font-face--helvetica-bold {
  font-family: $font--text;
  font-weight: 700;
  font-style: normal;
}

///
/// Headers
///

@mixin h1 {
  font-family: $font--heading;
  font-weight: normal;
  font-size: 35px;
  line-height: 40px;
  @include breakpoint($medium-up) {
    font-size: 50px;
    line-height: 60px;
  }
}

@mixin h2 {
  font-family: $font--text;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 18px;
    line-height: 24px;
  }
}

@mixin h2-bare {
  @include h2;
}

@mixin h3 {
  font-family: $font--text;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 15px;
    line-height: 24px;
  }
}

@mixin h4 {
  font-family: $font--text;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 15px;
    line-height: 18px;
  }
}

@mixin h5 {
  font-family: $font--text;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.02em;
  @include breakpoint($medium-up) {
    font-size: 15px;
    line-height: 16px;
  }
}

@mixin h6 {
  font-family: $font--text;
  font-weight: normal;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 14px;
    line-height: 26px;
  }
}

///
/// Text
///
@mixin t1 {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;
  @include breakpoint($medium-up) {
    font-size: 26px;
    line-height: 40px;
  }
}

@mixin t2 {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.06em;
  @include breakpoint($medium-up) {
    font-size: 16px;
    line-height: 26px;
  }
}

@mixin t3 {
  font-size: 8px;
  line-height: 12px;
  @include breakpoint($medium-up) {
    font-size: 12px;
    line-height: 17px;
  }
}

@mixin text-body {
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  @include breakpoint($medium-up) {
    font-size: 14px;
    line-height: 24px;
  }
}

@mixin text-pull_quote {
  font-size: 14px;
  line-height: 20px;
  font-weight: lighter;
  @include breakpoint($medium-up) {
    font-size: 26px;
    line-height: 40px;
  }
}

@mixin text-intro {
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  @include breakpoint($medium-up) {
    font-size: 16px;
    line-height: 26px;
  }
}

@mixin text-brandbook-script-heading {
  font-family: $font--heading;
  font-weight: normal;
  @include tracking(0);
  font-size: 35px;
  line-height: 40px;
  @include breakpoint($medium-up) {
    font-size: 50px;
    line-height: 60px;
  }
  @include breakpoint($brandbook-max-up) {
    font-size: 70px;
    line-height: 100px;
  }
}

@mixin text-brandbook-subheading {
  @include font-face--helvetica-light;
  @include tracking(0);
  font-size: 14px;
  line-height: 18px;
  @include breakpoint($medium-up) {
    font-size: 18px;
    line-height: 24px;
  }
  @include breakpoint($brandbook-max-up) {
    font-size: 30px;
    line-height: 38px;
  }
}

@mixin text-brandbook-quote {
  @include font-face--helvetica-light-oblique;
  font-size: 18px;
  line-height: 20px;
  @include tracking(0);
  @include breakpoint($medium-up) {
    font-size: 26px;
    line-height: 40px;
  }
  @include breakpoint($brandbook-max-up) {
    font-size: 45px;
    line-height: 54px;
  }
}

@mixin text-brandbook-attribution {
  @include font-face--helvetica-light-oblique;
  font-size: 14px;
  line-height: 18px;
  @include tracking(0);
  @include breakpoint($medium-up) {
    font-size: 18px;
    line-height: 24px;
  }
  @include breakpoint($brandbook-max-up) {
    font-size: 30px;
    line-height: 38px;
  }
}

@mixin text-brandbook-maintext {
  @include font-face--helvetica-light;
  font-size: 12px;
  line-height: 20px;
  @include tracking(20);
  @include breakpoint($medium-up) {
    font-size: 14px;
    line-height: 22px;
  }
  @include breakpoint($brandbook-max-up) {
    font-size: 23px;
    line-height: 32px;
  }
}
