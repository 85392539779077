///
/// @file global/_media-queries.scss
///
/// \brief Named media queries for easy use in nested selectors
///
/// In general these should be used in a mobile-first fashion, meaning
/// everything is coded for $small, then overridden for $medium-up and
/// overridden once more for $large-up. So you shouldn't even have to reference
/// the $small-*, $*-down or $*-only breakpoints, but they're here for now
/// just cause.
///

$small-down: max-width 767px;
$small-only: (0, 767px);
$small-up: 0px;

$med-small-down: max-width 767px;
$med-small-only: (481px, 767px);
$med-small-up: 481px;

$medium-down: max-width 1024px;
$medium-only: (768px, 1024px);
$medium-up: 768px;
$tablet-only: (768px 1024px); //IPad
$ipad-pro-portrait: 834px;
$ipad-pro-portrait-only: (width 834px 1194px) (orientation portrait); // iPad Pro portrait
$medium-portrait-only: (width 768px 1024px) (orientation portrait); // iPad portrait
$medium-landscape-only: (width 768px 1024px) (orientation landscape); // iPad landscape

$large-only: 1025px;
$large-up: 1025px;
$large-down: max-width 1220px; //UK CHECKOUT\\

$extra-large-only: 1281px;
$extra-large-up: 1281px;

$brandbook-up: 1281px;

$brandbook-max-up: 99999px;

$portrait-up: $medium-up;

$landscape-only: 1024px;
$landscape-up: 1024px;

$homepage-hero-max-width: 2200px;
