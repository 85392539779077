///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules. Don't use a mixin where you could use
/// @extend instead.
///

///
/// Text
///

@mixin text-body {
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  @include breakpoint($medium-up) {
    font-size: 14px;
    line-height: 24px;
  }
}

@mixin text-intro {
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  @include breakpoint($medium-up) {
    font-size: 16px;
    line-height: 26px;
  }
}

@mixin text-pull_quote {
  font-size: 14px;
  line-height: 20px;
  font-weight: lighter;
  @include breakpoint($medium-up) {
    font-size: 26px;
    line-height: 40px;
  }
}

@mixin text-brandbook-script-heading {
  font-family: $font--heading;
  font-weight: normal;
  @include tracking(0);
  font-size: 35px;
  line-height: 40px;
  @include breakpoint($medium-up) {
    font-size: 50px;
    line-height: 60px;
  }
  @include breakpoint($brandbook-max-up) {
    font-size: 70px;
    line-height: 100px;
  }
}

@mixin text-brandbook-subheading {
  @include font-face--helvetica-light;
  @include tracking(0);
  font-size: 14px;
  line-height: 18px;
  @include breakpoint($medium-up) {
    font-size: 18px;
    line-height: 24px;
  }
  @include breakpoint($brandbook-max-up) {
    font-size: 30px;
    line-height: 38px;
  }
}

@mixin text-brandbook-attribution {
  @include font-face--helvetica-light-oblique;
  font-size: 14px;
  line-height: 18px;
  @include tracking(0);
  @include breakpoint($medium-up) {
    font-size: 18px;
    line-height: 24px;
  }
  @include breakpoint($brandbook-max-up) {
    font-size: 30px;
    line-height: 38px;
  }
}

@mixin text-brandbook-maintext {
  @include font-face--helvetica-light;
  font-size: 12px;
  line-height: 20px;
  @include tracking(20);
  @include breakpoint($medium-up) {
    font-size: 14px;
    line-height: 22px;
  }
  @include breakpoint($brandbook-max-up) {
    font-size: 23px;
    line-height: 32px;
  }
}

/// Helvetica Neue LT Pro 45 Light
@mixin font--helvetica-neue-light {
  font-family: $font--text;
  font-weight: 300;
}

/// Edwardian Script ITC
@mixin font--edwardian-script {
  font-family: $font--heading;
}

/// Helvetica Neue LT Pro 45 Light
@mixin font--helvetica-neue-light {
  font-family: $font--text;
  font-weight: 300;
}

/// Helvetica Neue LT Pro 55 Roman
@mixin font--helvetica-neue-roman {
  font-family: $font--text;
  font-weight: normal;
}

/// Helvetica Neue LT Pro 65 Medium
@mixin font--helvetica-neue-medium {
  font-family: $font--text;
  font-weight: 500;
}

/// Helvetica Neue LT Pro 75 Bold
@mixin font--helvetica-neue-bold {
  font-family: $font--text;
  font-weight: 700;
}

///
/// Fluid Images for responsive designs
///
@mixin fluid-image {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

@mixin font-face--helvetica-light-oblique {
  font-family: $font--text;
  font-weight: 300;
  font-style: oblique;
}

@mixin font-face--helvetica-light {
  font-family: $font--text;
  font-weight: 300;
  font-style: normal;
}

@mixin font-face--helvetica-roman {
  font-family: $font--text;
  font-weight: normal;
  font-style: normal;
}

@mixin font-face--helvetica-medium {
  font-family: $font--text;
  font-weight: 500;
  font-style: normal;
}

@mixin font-face--helvetica-bold {
  font-family: $font--text;
  font-weight: 700;
  font-style: normal;
}

@mixin h4 {
  font-family: $font--text;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 15px;
    line-height: 18px;
  }
}

@mixin text-brandbook-quote {
  @include font-face--helvetica-light-oblique;
  font-size: 18px;
  line-height: 20px;
  @include tracking(0);
  @include breakpoint($medium-up) {
    font-size: 26px;
    line-height: 40px;
  }
  @include breakpoint($brandbook-max-up) {
    font-size: 45px;
    line-height: 54px;
  }
}

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input placeholders
///
@mixin input-styled-check($icon, $suffix: 'checked', $padding: 10px) {
  // hide the actual element (rdirection should match same as in _icons-mixins.scss)
  position: absolute;
  #{$rdirection}: 9999px;
  ~ label,
  ~ .label {
    @include icon($icon);
    &::before {
      margin-#{$rdirection}: $padding;
    }
  }
  &:checked {
    ~ label,
    ~ .label {
      @include icon($icon + '--' + $suffix);
    }
  }
}

///
/// Circles
///
/// Usage:
/// @include circle(200px, #999);
///
@mixin circle($default-circle-width: 41px, $color: #ccc) {
  width: $default-circle-width;
  height: $default-circle-width;
  background: $color;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction, border-color, [border-width], [cut])
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

@mixin bordered-triangle($size, $color, $direction, $border-color, $border-width: 1px, $cut: true) {
  $back-size: $size + ($border-width * 2);
  position: relative;
  min-width: $size;
  min-height: $size;
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
  }
  &:after {
    @include triangle($size, $color, $direction);
  }
  &:before {
    @include triangle($back-size, $border-color, $direction);
    $mid-border-width: ($border-width / 2);
    $border-width-and-mid: $border-width + $mid-border-width;
    @if ($cut == true) {
      $mid-border-width: 0;
      $border-width-and-mid: $border-width * 2;
    }
    @if ($direction == up) or ($direction == down) {
      margin-#{$ldirection}: -$border-width;
      @if ($direction == down) {
        margin-top: -$mid-border-width;
      } @else {
        margin-top: -$border-width-and-mid;
      }
    } @else if ($direction == right) or ($direction == left) {
      margin-top: -$border-width;
      @if ($direction == right) {
        margin-#{$ldirection}: -$mid-border-width;
      } @else {
        margin-#{$ldirection}: -$border-width-and-mid;
      }
    }
  }
}

///
/// Vertical centerer
/// Source: http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
/// Note: This doesn't work in old browsers (ie. IE), you have to use javascript
/// for them if it's important.
///
@mixin vertical-center {
  @include transform(translateY(-50%));
  position: relative;
  top: 50%;
}

@mixin destroy-vertical-center {
  @include transform(none);
  position: static;
  top: auto;
}

///
/// Vertical & Horizontal centerer
///
@mixin vertical-horizontal-center {
  position: relative;
  top: 50%;
  #{$ldirection}: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

// responsive container
@mixin responsive-container {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $max-width;
}

@mixin tracking($value) {
  letter-spacing: ($value/1000) + em;
}

// @TODO
@mixin line-behind-text() {
  position: relative;
  text-align: center;
  overflow: hidden;
  span {
    display: inline-block;
    position: relative;
    padding: 0 15px;
    vertical-align: baseline;
    zoom: 1;
    //*display: inline;
    //*vertical-align: auto;
    @include breakpoint($medium-up) {
      padding: 0 20px;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      width: 10000px;
      position: absolute;
      top: 6px;
      border-top: 1px solid $color-light-stone-gray;
      @include breakpoint($medium-up) {
        top: 9px;
      }
    }
    &:before {
      #{$rdirection}: 100%;
    }
    &:after {
      #{$ldirection}: 100%;
    }
  }
}

@mixin line-behind-header() {
  // Line though text decoration
  display: table;
  white-space: nowrap;
  &:before,
  &:after {
    border-top: 1px solid $color-darker-gray;
    content: '';
    display: table-cell;
    position: relative;
    top: 0.7em;
    width: 45%;
  }
  &:before {
    #{$rdirection}: 3%;
  }
  &:after {
    #{$ldirection}: 3%;
  }
}

@mixin center-hero-image($hero-image-height: 720px) {
  position: relative;
  min-height: 310px;
  @include breakpoint($medium-up) {
    height: $hero-image-height;
  }
  img {
    position: absolute;
    top: 0px;
    #{$ldirection}: 50%;
    transform: translate(-50%, 0%);
    height: 100%;
    width: auto;
    min-width: 100vw;
    max-width: none;
    @include breakpoint($medium-up) {
      min-width: auto;
    }
  }
}

@mixin brandbook-html-rules() {
  blockquote {
    @include text-brandbook-quote;
    margin: 12.5px 0px;
    @include breakpoint($medium-up) {
      margin: 25px 0px;
    }
    @include breakpoint($brandbook-max-up) {
      margin: 50px 0px;
    }
    text-align: #{$ldirection};
  }
  h2 {
    @include text-brandbook-script-heading;
    &.line-through {
      @include line-behind-header();
    }
  }
  h4 {
    @include text-brandbook-subheading;
  }
  p {
    @include text-brandbook-maintext;
  }
  p.attribution {
    @include text-brandbook-attribution;
    margin: 12.5px 0px;
    @include breakpoint($medium-up) {
      margin: 25px 0px;
    }
    @include breakpoint($brandbook-max-up) {
      margin: 50px 0px;
    }
  }
}

// Add ellipsis for browsers that don't support ellipsis yet
@mixin add-ellipsis($max-height) {
  padding-#{$rdirection}: 1rem;
  padding-#{$ldirection}: 1rem;
  position: relative;
  height: $max-height;
  overflow: hidden;
  &::before {
    content: '...';
    position: absolute;
    #{$rdirection}: 0;
    bottom: 0;
  }
  &::after {
    content: '';
    position: absolute;
    #{$rdirection}: 0;
    width: 1rem;
    height: 2.2rem;
    margin-top: 0.4rem;
    background: $white;
  }
}

@mixin button {
  @include font-smoothing;
  @include tracking(160);
  @include swap_direction(padding, 12px 35px 11px 35px);
  background: $color-charcoal;
  color: $color-white;
  border: 1px solid $color-charcoal;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: background $transition-duration $transition-effect,
    color $transition-duration $transition-effect,
    border-color $transition-duration $transition-effect;
  &:hover {
    background: #333333;
    text-decoration: none;
    color: $color-white;
  }
  @include breakpoint($medium-up) {
    font-size: 12px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

@mixin button-inverted {
  color: $color-black;
  background: $color-button-light-gray;
  border-color: $color-button-light-gray;
  &:hover {
    color: $color-black;
    border-color: $color-white;
    background: $color-white;
  }
}

@mixin button-secondary {
  background: $color-white;
  color: $color-black;
  border-color: $color-button-dark-border;
  &:hover {
    color: $color-button-light-gray;
    border-color: $color-button-dark-border;
    background: $color-button-dark-fill;
  }
  &--inverted {
    background: $color-black;
    color: $color-button-light-gray;
    border-color: $color-button-gray;
    &:hover {
      background: $color-button-light-gray;
      color: $color-black;
      border-color: $color-medium-stone-gray;
    }
  }
}

@mixin button-secondary-inverted {
  background-color: transparent;
  color: $color-button-light-gray;
  border: 1px solid $color-button-gray;
  &:hover {
    background-color: $color-button-light-gray !important;
    color: $color-black;
    border: 1px solid $color-button-light-gray;
  }
}

@mixin account_page_header {
  clear: both;
  line-height: 1em;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  border: 0;
  margin-#{$ldirection}: 0;
  margin-#{$rdirection}: 0;
  margin-bottom: 45px;
  @include h4;
  @include breakpoint($landscape-up) {
    padding: 0;
    margin-bottom: 80px;
  }
  @include line-behind-text();
}

@mixin account_page_sub_header {
  text-transform: uppercase;
  margin-bottom: 20px;
}

@mixin account_page_header_border {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: $border;
}
