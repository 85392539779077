
@import '../theme-bootstrap';

.engraving-preview-popup {
  border: 1px solid $color-darker-gray;
  @include breakpoint($medium-landscape-only) {
    max-width: $landscape-only;
  }
  @include breakpoint($ipad-pro-portrait-only) {
    width: $ipad-pro-portrait !important;
  }
  #cboxClose {
    color: $color-light-gray;
  }
  #cboxContent {
    @include breakpoint($medium-landscape-only) {
      max-width: $landscape-only;
    }
    @include breakpoint($ipad-pro-portrait-only) {
      max-width: $ipad-pro-portrait;
    }
  }
  #cboxLoadedContent {
    background: $color-black;
    color: $color-light-gray;
    padding-bottom: 55px;
    @include breakpoint($medium-landscape-only) {
      max-width: $landscape-only;
    }
    @include breakpoint($ipad-pro-portrait-only) {
      max-width: $ipad-pro-portrait;
    }
    .spp-engraving {
      position: relative;
      margin: 0 auto;
      max-width: 335px;
      @include breakpoint($medium-up) {
        max-width: $extra-large-up;
      }
      &__background-image-wrapper {
        text-align: center;
        @include breakpoint($medium-up) {
          float: $rdirection;
          text-align: $ldirection;
          width: 47%;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          width: 50%;
        }
        .spp-engraving__background-image {
          width: 70%;
          &.engraving-type-8 {
            padding-top: 60px;
            width: 54%;
          }
          @include breakpoint($medium-up) {
            padding-#{$ldirection}: 22px;
            padding-top: 98px;
            width: 64%;
            &.engraving-type-8 {
              padding-#{$ldirection}: 32px;
            }
          }
          @include breakpoint($medium-portrait-only) {
            padding-top: 100px;
            width: 100%;
            &.engraving-type-8 {
              padding-#{$ldirection}: 75px;
              padding-top: 75px;
              width: 100%;
            }
          }
          @include breakpoint($medium-landscape-only) {
            width: 80%;
            &.engraving-type-8 {
              padding-#{$ldirection}: 100px;
              width: 75%;
            }
          }
          @include breakpoint($ipad-pro-portrait-only) {
            width: 90%;
          }
        }
      }
      &__background--content {
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 90px 0 0 220px);
          display: inline-block;
          width: 52%;
        }
        @include breakpoint($medium-portrait-only) {
          @include swap_direction(padding, 90px 0 0 50px);
        }
        @include breakpoint($medium-landscape-only) {
          @include swap_direction(padding, 90px 0 0 100px);
        }
        @include breakpoint($ipad-pro-portrait-only) {
          @include swap_direction(padding, 90px 0 0 10px);
          width: 50%;
        }
      }
      &__product-title {
        font-family: $font--heading;
        font-size: 32px;
        line-height: 1;
        @include breakpoint($medium-up) {
          font-size: 50px;
        }
      }
      &__product-sku-label {
        margin-top: 13px;
      }
      &__engraving-description {
        line-height: 1.25;
        margin-top: 20px;
      }
      .error_messages {
        color: $color-red;
      }
      &__form-line {
        color: $color-darker-gray;
        margin-top: 21px;
        position: relative;
        .engraving-message {
          background: $color-black;
          border-color: $color-darker-gray;
          color: $color-white;
          font-family: $font--english--regular;
          font-size: 23px;
          height: 50px;
          text-indent: 10px;
          width: 100%;
          &:focus {
            border-color: $color-darker-gray;
          }
          &.error {
            border-color: $color-red;
          }
        }
        .char-counter {
          #{$rdirection}: 22px;
          position: absolute;
          top: 12px;
        }
      }
      .engraving-form-count {
        display: none;
      }
      &__form-preview {
        .engrave-placement {
          #{$rdirection}: 100px;
          color: $color-light-gold;
          font-family: $font--english--regular;
          font-size: 18px;
          position: absolute;
          text-align: center;
          top: 225px;
          width: 125px;
          &.engraving-type-8 {
            #{$rdirection}: 65px;
            color: $white;
            font-size: 15px;
            top: 350px;
            width: 200px;
            word-spacing: -2px;
          }
          &.engraving-type-56 {
            color: $color-light-gray-engrave;
          }
          @include breakpoint($medium-up) {
            #{$rdirection}: 264px;
            font-size: 28px;
            top: 410px;
            width: 200px;
            word-spacing: -2px;
            &.engraving-type-8 {
              #{$rdirection}: 286px;
              font-size: 26px;
              top: 477px;
            }
          }
          @include breakpoint($medium-portrait-only) {
            #{$rdirection}: 75px;
            font-size: 25px;
            top: 390px;
            width: 150px;
            word-spacing: -2px;
            &.engraving-type-8 {
              #{$rdirection}: 21px;
              font-size: 23px;
              top: 473px;
            }
          }
          @include breakpoint($medium-landscape-only) {
            #{$rdirection}: 205px;
            font-size: 28px;
            top: 442px;
            width: 150px;
            word-spacing: 2px;
            &.engraving-type-8 {
              #{$rdirection}: 145px;
              font-size: 24px;
              top: 470px;
            }
          }
          @include breakpoint($ipad-pro-portrait-only) {
            #{$rdirection}: 140px;
            font-size: 25px;
            top: 445px;
          }
          input {
            opacity: 0;
            width: 100%;
          }
          .preview-copy {
            background: none;
            border: 0;
            height: 100%;
            letter-spacing: normal;
            padding: 0;
            text-align: center;
            white-space: nowrap;
            width: 100%;
          }
        }
      }
      &__form-element-subtotal {
        margin-top: 28px;
        .name {
          display: inline-block;
        }
        .product {
          &-size {
            width: 80%;
          }
          &-title,
          &-subtitle {
            display: inline;
          }
        }
        .product-cost,
        .engraving-cost,
        .total-cost {
          float: $rdirection;
        }
        .engraving-wrap {
          margin-top: 10px;
        }
        .subtotal-wrap {
          margin-top: 9px;
        }
      }
      &__form-element-footer {
        padding-top: 32px;
        .engraving-msg {
          font-size: 10px;
          margin-top: 20px;
        }
      }
    }
  }
}

.engraving-overlay-on {
  .site-header {
    z-index: 10000;
  }
  .site-content,
  .viewcart,
  .checkout__header,
  .site-footer {
    display: none;
  }
  #cboxOverlay {
    border: 0;
  }
  #colorbox {
    top: 50px !important;
  }
}

.product-full__buttons,
.product-quickshop__buttons {
  .product-quickshop__engraving-button-wrapper,
  .product-full__engraving-button-wrapper {
    background: $color-black;
    border: 1px solid $color-darker-gray;
    cursor: pointer;
    margin: 25px 0 20px;
    padding: 5px;
    .product-full__engraving-button,
    .product-quickshop__engraving-button {
      background: $color-near-black;
      border: 1px solid $color-darker-gray;
      color: $color-white;
      padding: 9px;
      &:hover {
        background: $color-near-black;
        border-color: $color-darker-gray;
        color: $color-white;
      }
    }
  }
}

.cart-item {
  &.engraving-added {
    .cart-items__item--total {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    .cart-items__item--price,
    .cart-items__item--total {
      @include breakpoint($medium-portrait-only) {
        display: none;
      }
    }
    .cart-item__total {
      &.engraving-sku-total {
        @include breakpoint($medium-up) {
          float: $rdirection;
          margin-#{$rdirection}: 18px;
          margin-top: 25px;
        }
        @include breakpoint($medium-portrait-only) {
          float: $ldirection;
          margin-#{$ldirection}: 0;
          margin-top: -2%;
        }
      }
      @include breakpoint($medium-portrait-only) {
        display: inline-block;
        margin-#{$ldirection}: -10%;
      }
    }
    .cart-item__qty-label {
      @include breakpoint($medium-portrait-only) {
        float: $ldirection;
        text-transform: uppercase;
      }
    }
    .cart-item__qty {
      @include breakpoint($medium-portrait-only) {
        text-align: $ldirection !important;
      }
    }
  }
  &__engraved-text {
    text-transform: none;
  }
  &.engravable-item {
    .cart-items__item--total {
      margin-top: -115px;
      @include breakpoint($medium-up) {
        margin-top: 14px;
      }
      @include breakpoint($medium-portrait-only) {
        margin-top: -115px;
      }
    }
  }
  &__engraving-material-price {
    margin: 5px 0;
  }
  &__engraved-sku-price {
    display: inline-block;
    margin-top: 5px;
    @include breakpoint($medium-up) {
      display: none;
    }
    @include breakpoint($medium-portrait-only) {
      display: inline-block;
    }
  }
  &__engraved-sku-total {
    float: $rdirection;
    font-weight: bold;
    margin-top: 5px;
    @include breakpoint($medium-portrait-only) {
      display: block;
    }
  }
  &__engraving {
    color: $color-black;
    padding-top: 15px;
    text-transform: none;
    &-copy {
      font-weight: bold;
    }
    &-message {
      color: $color-light-gold;
      font-family: $font--english--regular;
      font-size: 24px;
      margin-#{$ldirection}: 33px;
    }
  }
  &__engraving-price {
    &.total {
      display: none;
      font-weight: bold;
      margin-top: 182px;
      transform: translateX(65px);
      @include breakpoint($medium-up) {
        display: inline-block;
      }
    }
  }
}

.engraving-wrapper {
  padding-top: 15px;
  @include breakpoint($medium-up) {
    border-top: solid 1px $color-light-gray;
    margin-top: 20px;
  }
  .engraving-view {
    &__engraving-copy {
      border-bottom: 1px solid;
    }
    &__engraving-message {
      font-size: 14px;
      line-height: 1;
    }
    &__message-cta {
      padding-top: 4px;
    }
    &__message-edit,
    &__message-delete {
      background: $color-white;
      font-size: 10px;
      line-height: 1;
      padding: 10px;
      width: 45%;
    }
  }
}

.engraving-view__message-disclaimer {
  p {
    font-size: 10px;
    padding-top: 5px;
    width: 80%;
    @include breakpoint($medium-up) {
      width: auto;
    }
  }
  .checkout__sidebar & {
    p {
      width: auto;
      @include breakpoint($medium-up) {
        white-space: normal;
      }
    }
  }
}
