///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand

// Layout
$max-width: 1280px !default;
$max-width-large: 1920px !default;
$header-height: 49px !default; // used to offset anchors, etc.
$medium-up-header-height: 138px !default;
$ipad-header-height: 80px !default;

// Typography
//$base-font-size: 13px !default;
$base-font-size-mobile: 12px;
$base-line-height-mobile: 1.666667; // 20/12
$base-font-size-desktop: 14px;
$base-line-height-desktop: 1.714285; // 24/14

$text-direction: ltr !default;
$rdirection: right !default;
$ldirection: left !default;

// Colors (http://sachagreif.com/sass-color-variables/)
$color-white: #ffffff !default;
$color-black: #000000 !default;
$color-almost-black: #111111 !default;
$color-near-black: #1f1f1f !default;
$color-gray: #c7c3c3 !default;
$color-grey: $color-gray !default;
$color-light-gray: #e9e9e9 !default;
$color-light-gray-engrave: #efefef !default;
$color-lighter-gray: #f6f6f6 !default;
$color-dark-gray: #999999 !default;
$color-darker-gray: #666666 !default;
$color-dark-gray-arrow: #666666 !default;
$color-light-gray-arrow: #e2e2e2 !default;
$color-darker-gray: #6b6b6b !default;
$color-darker-gray-hr: #c2c2c2 !default;
$color-red: #cd3030 !default;
$color-pink: #ff4661 !default;
$color-blue: #2121d1 !default;
$color-light-brown: #f7f4ef !default;
$color-perfume-stories-gray: #ebebeb;
$color-button-gray: #555555 !default;
$color-button-dark-gray: #2e2e2e !default;
$color-button-light-gray: #e2e2e2 !default;
$color-button-dark-border: #222222 !default;
$color-button-dark-fill: #333333 !default;
$color-pull-quote-gray: #e2e2e2 !default;
$color-util-icon: #cdcccc !default;
$color-button-icon-light: #d3d3d3 !default;
$color-util-rollover-text: #e2e2e2 !default;
$color-thumbnail-border-grey: #e2e2e2 !default;
$color-blue-shade: #040a2b !default;
$color-whisper-shade: #eee !default;
$color-alice-blue: #f2f3f4 !default;
$color-darkest-gray: #696969;
$color-facebook: #3c5a99 !default;
$color-vkontakte: #4a76a8 !default;
$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;
$tick: '\2713';
$cross: '\2715';
$color-success: #458745 !default;
$color-light-gold: #a1805d !default;

// Brand styleguide names
$color-silver: $color-light-gray;
$color-light-silver: $color-lighter-gray;
$color-stone-gray: $color-darker-gray;
$color-stone-grey: $color-stone-gray;
$color-medium-stone-gray: $color-dark-gray;
$color-medium-stone-grey: $color-medium-stone-gray;
$color-light-stone-gray: $color-gray;
$color-light-stone-grey: $color-light-stone-gray;
$color-charcoal: $color-almost-black;

$color-menu-background: #f3f3f3 !default;
$color-menu-text: #fdfdfd !default;
$color-submenu-text: #444444 !default;
$color-menu-border-gray: #f2f2f2 !default;

$color-filter-sort-inactive: #737373 !default;
$color-filter-menu-item-border: #d3d3d3 !default;

$color-text: $color-black !default;
$color-link: $color-black !default;
$color-link-hover: $color-stone-gray !default;
$color-border: $color-gray !default; // @TODO - styleguide says #666666 but designs are #c7c3c3
$black: $color-black !default;
$white: $color-white !default;

$brandbook-dark-gray: $color-near-black !default;
$brandbook-menu-background: $color-almost-black !default;
$color-transparent--black: rgba(0, 0, 0, 0.5) !default;

$dark-gray: #696969;

// Default transition settings
$transition-duration: 0.3s;
$transition-effect: ease-in-out;

// common variables
$border: $black solid 1px !default;
$border-grey: $color-gray solid 1px !default;

// frontend validation variables
$border-color-normal: $color-stone-gray;
$border-color-error: $color-red;
$color-valid: green;
$color-error: $color-red;
$icon-background: white;
$inline-error-background: white;

// transparent colors (primarily for overlays)
$color-transparent-white: rgba(255, 255, 255, 0.85) !default;
$color-transparent-black: rgba(0, 0, 0, 0.85) !default;
$color-transparent-black-light: rgba(0, 0, 0, 0.2) !default;
$color-transparent-light: $color-transparent-white;
$color-transparent-dark: $color-transparent-black;

// Offer Message Color
$color-success-message: #087443;
$color-error-message: #a80000;
$color-alert-message: #b54708;

//Extra color variables
$color-bg-black: #0d0707;
$color-near-gray: #423e3d;
$color-near-red: #921f20;
$color-darker-red: #490906;
$color-alert-red: #b11624;
$color-light-dark-red: #7b1417;
$color-near-solid-gray: #bbbbbb;
$color-near-solid-red: #aa1c1c;
$color-near-solid-white: #f1f1f1;
$color-green: #07bb5b;

$color-gray-dim: #696969;

// If you have questions about why these aren't classes
// (http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/)
// It's because that method triggers compatability mode:
// https://github.com/h5bp/html5-boilerplate/issues/1187
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
$ie11: "html[data-useragent*='MSIE 11.0']";

// Compass variables
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

// Search
// @todo cleanup
$mobile_search_header_height: 60px;
$pc-search-width: 551px;
$search-mobile-margin: 8.125%;
$color-search-bg: #f2f2f2 !default;

$mobile-bio-spacing: 35px;
$pc-content-well-padding: 50px;

$mobile-menu-left-padding: 18px;
$device-mobile-breakpoint: 768px;

// Typography
$roboto-text: 'roboto-mono_regular', Helvetica, Arial, Sans-Serif !default;

//Checkout styles
$cart-button-disable: false !default;

// Enable stylings for error message for hazmat product with restricted ingredients.
$enable-restrict-ingredient-error: false !default;
